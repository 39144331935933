
// firebase-messaging-sw.js
if ('function' === typeof importScripts) {
    importScripts('https://www.gstatic.com/firebasejs/8.9.1/firebase-app.js');
    importScripts('https://www.gstatic.com/firebasejs/8.9.1/firebase-messaging.js');

    firebase.initializeApp({
        apiKey: "AIzaSyC5XoOKdATo3raTS_fzO_EPLanv0i13vD0",
        authDomain: "weststein-app.firebaseapp.com",
        databaseURL: "https://weststein-app.firebaseio.com",
        projectId: "weststein-app",
        storageBucket: "weststein-app.appspot.com",
        messagingSenderId: "997455524520",
        appId: "1:997455524520:web:b5c4d7f7287957b1d4089d",
        measurementId: "G-9K4T3KPZMR"
    });

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('./firebase-messaging-sw.js')
            .then(function (registration) {
                console.log('Registration successful, scope is:', registration.scope);
            }).catch(function (err) {
                console.log('Service worker registration failed, error:', err);
            });
    }


    const messaging = firebase.messaging();
    messaging.usePublicVapidKey("BEQCX80YDkSpPvbZzylihR_9quL9C5aA0TBU6B8uqjmyDPS6RQm8tMMsoFLA7C5vNNJGUbCoUErWQLPIMyM9lE8");
    messaging.onBackgroundMessage(payload => {
        console.log(
            '[firebase-messaging-sw.js] Received background message ',
            Date.now().toLocaleString("ru", {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }),
            payload
        );
        // parameters = payload.data.parameters;
        // console.log(parameters);
        // parameters = JSON.parse(parameters);
        // console.log(parameters);
        // for (val in parameters) {
        //     console.log(JSON.parse(parameters[val]));
        // }
        // Customize notification here

        // console.log('Background message received : ', payload);
        
        const messageData = JSON.parse(payload.data.parameters);

        const notificationTitle = messageData.title;
        const notificationOptions = {
            body: messageData.text,
            icon: '/weststein-card.svg'
        };

        self.registration.showNotification(notificationTitle,
            notificationOptions);
    });
}
